<h1>Op de planning</h1>
<div class="giglist-container">
  <table class="giglist-table">
    <tbody>
      <ng-container *ngFor="let date of dates; let i = index">
        <tr *ngIf="i < 4 || showMore">
          <td class="date">{{ date }}</td>
          <td class="eventNames">{{ eventNames[i] }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<span *ngIf="showMoreButton" (click)="toggleShowMore()">
    ...<br/>
    {{ showMore ? 'Minder info' : 'Meer info' }}
</span>
