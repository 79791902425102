import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
  <div class="container-fluid">
    <div class="row">
    <div class="col-12 center title">
    <a id="footer"><b>Gras van de Buren</b></a>
    <br/>
    </div>
    <div class="col-6 links">
    Vince Linders<br/>
    Peter van Kessel<br/>
    </div><div class="col-6">
    <a href="tel:+31681728122"><i class="fas fa-phone"></i>+316 8172 8122</a> <br/>
    <a href="tel:+31630103980"><i class="fas fa-phone"></i>+316 3010 3980</a> <br/>
    </div><div class="col-12 col-sm-6 leftOrCenter">
    info@grasvandeburen.com <br/>
    </div><div class="col-12 col-sm-6 rechts">
    Marktstraat 7, Helmond <br/>
    </div><div class="col-12 col-sm-6 leftOrCenter">
    Btw: 857349910B01
    </div><div class="col-12 col-sm-6 rechts">
    Kvk: 68220944
    </div>
    </div>
    <br/>
  </div>
  `,
  styles: [`
            div {background-color: #d1d1d1;}
            i.fas {font-size: 13px}
            .links, .leftOrCenter {text-align: right; border-right: 1px;}
            @media (max-width: 576px) {
              /* CSS that should be displayed if width is equal to or less than 800px goes here */
              .leftOrCenter {text-align: center; border-right: 1px;}
              .rechts {text-align: center; border-right: 1px;}
            }
            .title {margin-top: 10px; font-size: 18px;}
            .center {text-align: center; }
            b {font-family: 'Walter Turncoat';}
           `]
})
export class FooterComponent {

  constructor() { }

  ngOnInit() {
  }

}
