import { Component, OnInit } from '@angular/core';
import { PartnersService } from './partners.service';
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css'],
  providers: [PartnersService]
})

export class PartnersComponent {


  constructor(private partnersService: PartnersService) { }
  ngOnInit() {
    //this.partnersService.testFunction();
  }

}

