<div class="container">
    <div class="row">
        <div class="col-11 col-sm-12 mid">
            <app-navigationbar></app-navigationbar>     
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<app-giglist></app-giglist>
<app-partners></app-partners> 
<app-footer></app-footer>