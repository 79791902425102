<div class="actInfoShort row">
    <!--picture small screen-->
    <div class="col-12 col-md-5 projectImg d-md-none" style="border-top-left-radius: 30px;">
    </div>
    <!--picture-->
    <div class="col-12 col-md-5 projectImg d-none d-md-block order-md-12 order-1" style="border-bottom-right-radius: 30px;">
    </div>
    <!-- tekst part -->
    <div class="col-12 col-md-7 m-0 p-0 ">
        <h1 i18n>Pagina onvindbaar</h1>
        <p i18n class="text">Oeps!
        <br/><br/>Er lijkt iets fout gegaan. De pagina waar je naartoe wilde lijkt niet te bestaan. Eigenlijk is onze website maar een pagina dus alle andere pagina's zijn eigenlijk 'verkeerd'. Maar wat is verkeerd, als je zocht naar de 404 pagina dan heb je hem bij deze gevonden en kloppen de eerder genoemde aantijgingen niet. In dat geval excuses voor het ongemak.
        </p>
    </div>
</div>