import { Injectable } from '@angular/core';
import '@angular/localize/init';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor() { }

  getProjects() {
     let idCounter: number = 0

   

  return [{
      id: idCounter++,
      title: $localize`:@@titleTTT:Turbo Touw Tornado`,
      info: $localize`:@@infoTTT:Door sommigen wordt het omschreven als een vreemde, opzwepende kermisattractie. Anderen zien het als de perfecte warming up om in de feeststemming te komen. Turbo Touw Tornado is een geautomatiseerd draaiend meerpersoons springtouw waar de deelnemer op elk moment kan inspringen! <br/><br/>Maar naast non-stop touwspringen, is het een theatraal-interactieve act met meerdere acteurs. Ieder in hun eigen rol verlagen zij de drempel voor de bezoekers om in te springen.`,
      image: "./assets/TTT.webp",
      imageText: $localize`:@@imageTextTTT:Turbo Touw Tornado op Daisy festival door Gras van de Buren`,
      href: 'turbotouwtornado',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado IX.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado IX - Thumbnail.webp', alt: $localize`:@@altTextTTT9:Turbo Touw Tornado op Concert At Sea 2023 - Foto: Ralph Oors`},{
        video: 'https://youtu.be/SpdNM88Hf20', posterImage: 'assets/slideshow/TTT/video Thumbnail.webp',   alt: $localize`:@@altTextTTT2:Turbo Touw Tornado straatheater act video`},{
        image: 'assets/slideshow/TTT/Gras van de Buren - Turbo Touw Tornado - Rider.webp', thumbImage: 'assets/slideshow/TTT/Gras van de Buren - Turbo Touw Tornado - Rider Thumbnail.webp', alt: $localize`:@@altTextTTT4:Turbo Touw Tornado Rider`},{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado XIII.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado XIII - Thumbnail.webp', alt: $localize`:@@altTextTTT8:Turbo Touw Tornado op Freshtival`},{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado X.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado X - Thumbnail.webp', alt: $localize`:@@altTextTTT3:Turbo Touw Tornado op Daisy Festival`},{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado XI.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado XI - Thumbnail.webp', alt: $localize`:@@altTextTTT5:Turbo Touw Tornado op Camping Buitenland`},{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado II.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado II - Thumbnail.webp', alt: $localize`:@@altTextTTT6:Turbo Touw Tornado op Georgie\'s Wundergarten 2018 - Janneke Nooij`},{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado I.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado I - Thumbnail.webp', alt: $localize`:@@altTextTTT7:Turbo Touw Tornado op Paaspop 2019 - Foto: Tom Doms`},{
        image: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado XII.webp', thumbImage: 'assets/slideshow/TTT/Gras van de buren - Turbo Touw Tornado XII - Thumbnail.webp', alt: $localize`:@@altTextTTT1:Turbo Touw Tornado op Camping Buitenland`
        }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoTTT:Zo zorgt de machinist met een noodstop voor het veilig verloop van het rondzwierende touw. Daarnaast bedient hij de knoppen van het soundboard vol komische, zelf ingesproken, kermisgeluiden. <br/><br/> Vanuit zijn veel te krappe loket speurt de \'springbewijs\' stempelaar lokkend en loerend naar interactie en ongein. Hij deelt onbeperkt overwinningstrofeën uit: een geldig spring-bewijs, een certificaat waar de bezoekers fier mee rond kunnen paraderen. En dan zijn er nog springinstructeurs, die het touw bij iedere missprong weer vastmaken aan de draaischijf. Zij rennen rond het touw om de boel fysiek op te zwepen. Op een aanstekelijke manier zetten zij de toeschouwers aan om ook mee te doen en in te springen. <br/><br/>`
    },
    {
      id: idCounter++,
      title: $localize`:@@titleSTOEL:Stoel én Dans`,
      info: $localize`:@@infoSTOEL:Ga er maar eens rustig voor zitten, en zet je zitspieren aan het werk met deze muzikale zetel battle. Niet veel mensen staan er bij stil, maar met een razendsnelle bevolkingsgroei is een tekort aan stoelen onvermijdelijk. Met meer mensen dan stoelen is het zaak dat je een eigen plekje weet te bemachtigen. 
      </br></br>
      Met onze warming up wordt het zitvlak klaargestoomd op deze knetterharde afvalrace. Na een kort maar intieme tango met de stoel is iedereen klaar om in de startblokken te staan.
      `,
      image: "./assets/STOEL.webp",
      imageText: $localize`:@@imageTextSTOEL:`,
      href: 'stoelendans',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo1.webp', thumbImage: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo1 Thumbnail.webp', alt: $localize`:@@altTextSTOEL1:Stoel én Dans`},{
        video: 'https://youtu.be/qIcMg_wGhrU', posterImage: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo4 Thumbnail.webp', alt: $localize`:@@altTextBROM2:Stoel én Dans video - straattheater act`},{
        image: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Rider.webp', thumbImage: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Rider Thumbnail.webp', alt: $localize`:@@altTextSTOEL1:Stoel én Dans`},{
        image: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo3.webp', thumbImage: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo3 Thumbnail.webp', alt: $localize`:@@altTextSTOEL1:Stoel én Dans`},{
        image: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo2.webp', thumbImage: 'assets/slideshow/STOEL/Gras van de Buren - Stoel en Dans - Promo2 Thumbnail.webp', alt: $localize`:@@altTextSTOEL1:Stoel én Dans`
      }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoSTOEL: Snelheid en alertheid is bij deze krachtmeting van belang om niet van de troon gestoten te worden. Daarbovenop valt de muziek ook nog eens van tijd tot tijd uit. Driftig dansen tot je er warmpjes bij zit, als er nog een stoel over is tenminste Haperende muziek, een kapstok vol stoelen en nét iets meer mensen. We draaien er niet omheen, met deze dans der stoelen zit je goed.
      </br></br>
      In co-productie met <a target="_blank" href="https://www.deschoenfabriek.nl">De Schoenfabriek</a>
      `
    },
    {
      id: idCounter++,
      title: $localize`:@@titleBUB:Bubbouillon`,
      info: $localize`:@@infoBUB:Laat je onderdompelen in een hottub die van de nodige wellness wordt voorzien. Chef Vincént en chef Pièrre zorgen voor een theatrale kookpot gevuld met badkruiden, knollen, grappen en grollen, waardoor het badderen volledig in de soep loopt. <br/> <br/>Bubbouillon is een mengelmoes van verse, ontspannende en veganistische wellness met een scheutje straattheater. Komt dat zien, komt dat proeven!`,
      image: "./assets/BUB.webp",
      imageText: $localize`:@@imageTextBUB:Bubbouillon - Gras van de Buren`,
      href: 'bubbouillon',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon III.webp', thumbImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon III - Thumbnail.webp', alt: $localize`:@@altTextBUB1:Bubbouillon`},{
        video: 'https://www.youtube.com/watch?v=yCICkTiMjIs', posterImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon I - Thumbnail.webp', alt: $localize`:@@altTextBUB2:Bubbouillon video - straattheater act`},{
        image: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon Rider.webp', thumbImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon Rider - Thumbnail.webp', alt: $localize`:@@altTextBUB3:Bubbouillon Rider`},{
        image: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon VI.webp', thumbImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon VI - Thumbnail.webp', alt: $localize`:@@altTextBUB4:Bubbouillon`},{
        image: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon II.webp', thumbImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon II - Thumbnail.webp', alt: $localize`:@@altTextBUB4:Bubbouillon`},{
        image: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon IV.webp', thumbImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon IV - Thumbnail.webp', alt: $localize`:@@altTextBUB4:Bubbouillon`},{
        image: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon V.webp', thumbImage: 'assets/slideshow/BUB/Gras van de buren - Bubbouillon V - Thumbnail.webp', alt: $localize`:@@altTextBUB4:Bubbouillon`
        }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoBUB:Bubbouillon is een licht verteerbare ‘kannibalistische’ soep, vermomd als wellnessbehandeling. Spieren worden losgerold met deegrollers en oorlellen soepel gekneed met oorlelolie. De badgasten komen volledig tot rust, tótdat  de lachspieren aan de beurt zijn! Stap voor stap wordt het helderder dat zij zelf het hoofdingrediënt zijn van deze rijkelijk gevulde soep.<br/>
      Als grande finale wordt er badwater uit de hottub geschept, vindt er achter de schermen een wisseltruc plaats en kunnen de hoofdingrediënten hun 'zelf getrokken’ soepje proeven met daarin hun eigen naam in lettervermicelli.`
    },
    {
      id: idCounter++,
      title: $localize`:@@titleBROM:Hobbel Brommobiel`,
      info: $localize`:@@infoBROM:Waar loop je direct tegenaan als je de Scapino uit loopt? Juist, dat speeltoestel met het autootje waarin je nét niet comfortabel zit,  met je volwassen benen. Gelukkig brengt Gras van de Buren deze hobbelige ervaring in realtime 4D.<br/><br class="d-none d-lg-block">Je bereikt oorverdovende windsnelheden op deze - niet vooruit te branden - brommer. De vliegen vliegen je om de oren tijdens deze tour over het hobbelige parcours van 2m².`,
      image: "./assets/BROM.webp",
      imageText: $localize`:@@imageTextBROM:Hobbel Brommobiel op Heavon Open Air - Gras van de Buren`,
      href: 'HobbelBrommobiel',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo1.webp', thumbImage: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo1 - thumbnail.webp', alt: $localize`:@@altTextBROM1:Hobbel Brommobiel`},{
        video: 'https://youtu.be/JvMEujRNr3Q', posterImage: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel video thumbnail.webp', alt: $localize`:@@altTextBROM2:Hobbel Brommobiel video - straattheater act`},{
        image: 'assets/slideshow/BROM/Gras van de Buren - Hobbelbrommobiel - Rider.webp', thumbImage: 'assets/slideshow/BROM/Rider Bromkleur.webp', alt: $localize`:@@altTextBROM3:Hobbel Brommobiel Rider`},{
        image: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo2.webp', thumbImage: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo2- thumbnail.webp', alt: $localize`:@@altTextBROM4:Hobbel Brommobiel`},{
        image: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo3.webp', thumbImage: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo3- thumbnail.webp', alt: $localize`:@@altTextBROM4:Hobbel Brommobiel`},{
        image: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo4.webp', thumbImage: 'assets/slideshow/BROM/Gras van de Buren - Hobbel Brommobiel Promo4- thumbnail.webp', alt: $localize`:@@altTextBROM4:Hobbel Brommobiel`
        }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoBROM:Met een speeltoestel, groot genoeg om je volwassen benen kwijt te kunnen en met een live gespeeld muziekje tijdens deze heftige rit kun je, samen met je nageslacht, genieten van deze intense ervaring.<br/>
      Voordat je start met je rit controleren we op het bezit van een geldig, of ongeldig, rijbewijs. Het dragen van een helm is niet verplicht, wel adviseren we het dragen van een alternatief hoofddeksel met veiligheidsklasse 1.     
      `
    },
    {
      id: idCounter++,
      title: $localize`:@@titlePLET:PletPortret`,
      info: $localize`:@@infoPLET:In dit erg krappe reproductiekantoortje vinden belangrijke zaken plaats. De bezoeker worden wat korte vragen gesteld. Daarna komt het kopieerapparaat van pas om hen te archiveren. </br></br>Door het gezicht op de glasplaat van het apparaat te drukken wordt duidelijk dat er een fotograaf in het kopieerapparaat zit verstopt. FLITS, een Pletportret is gemaakt.`,
      image: "./assets/PLET.webp",
      imageText: $localize`:@@imageTextPLET:PletPortret - Gras van de Buren`,
      href: 'PletPortret',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/PLET/Pletportret10.webp', thumbImage: 'assets/slideshow/PLET/Pletportret10 - thumbnail.webp', alt: $localize`:@@altTextPLET1:PletPortret`},{
        image: 'assets/slideshow/PLET/Pletportret11.webp', thumbImage: 'assets/slideshow/PLET/Pletportret11 - thumbnail.webp', alt: $localize`:@@altTextPLET5:PletPortret`},{
        image: 'assets/slideshow/PLET/Rider Pletportret.webp', thumbImage: 'assets/slideshow/PLET/Rider Pletportret thumbnail.webp', alt: $localize`:@@altTextPLET2:PletPortret`},{
        image: 'assets/slideshow/PLET/Pletportret9.webp', thumbImage: 'assets/slideshow/PLET/Pletportret9 - thumbnail.webp', alt: $localize`:@@altTextPLET3:PletPortret`},{
        image: 'assets/slideshow/PLET/Pletportret8.webp', thumbImage: 'assets/slideshow/PLET/Pletportret8 - thumbnail.webp', alt: $localize`:@@altTextPLET4:PletPortret`},{
        image: 'assets/slideshow/PLET/Pletportret4.webp', thumbImage: 'assets/slideshow/PLET/Pletportret4 - thumbnail.webp', alt: $localize`:@@altTextPLET6:PletPortret`
      }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoPLET:Zodra het geplette portret uit het apparaat komt rollen wordt duidelijk dat er iets technisch mis is gegaan: het is niet het eigen portret, maar dat van een voorganger. Nu is het aan de bezoeker om het eigen portret terug te vinden en ervoor te zorgen dat het gekregen portret bij de juiste persoon terecht komt.
      </br></br>
      Het uitwisselen van de Pletportretten stimuleert dat mensen met elkaar contact leggen. Het biedt een aanknopingspunt om een gesprekje aan te gaan. Het Pletportret is ook een ludieke manier om te netwerken op een bedrijfsevent of op een festival met een kopie van jezelf dubbel zo hard te kunnen feesten.         
      `
    },
    {
      id: idCounter++,
      title: $localize`:@@titleMATCH:Matchfixers`,
      info: $localize`:@@infoMATCH: Met een soepele slag sla je de bal naar de overkant. In! Of toch uit? Hoe groot is het veld eigenlijk? Is er wel een veld? En wie speelt er eigenlijk allemaal mee?
      </br></br>
      Spelen zonder regels is eigenlijk iets wat alleen kinderen écht doen. Wij brengen deze ervaring weer terug naar de volwassen mens. Gewild of niet, het spel wat ze denken te spelen veranderd in allerlei opzichten...`,
      image: "./assets/MATCH.webp",
      imageText: $localize`:@@imageTextMATCH:Matchfixers - Gras van de Buren`,
      href: 'Matchfixers',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/MATCH/matchfixers2.webp', thumbImage: 'assets/slideshow/MATCH/matchfixers2 - thumbnail.webp', alt: 'Gras van de Buren - Matchfixers - foto door SopaMedia'},{
        video: 'https://youtu.be/2PMkKev3Kyc', posterImage: 'assets/slideshow/MATCH/matchfixers1.webp', alt: 'Video Matchfixers'},{
        image: 'assets/slideshow/MATCH/Rider Matchfixers.webp', thumbImage: 'assets/slideshow/MATCH/rider matchfixers - thumbnail.webp', alt: 'Gras van de Buren - rider Matchfixers'},{
        image: 'assets/slideshow/MATCH/matchfixers3.webp', thumbImage: 'assets/slideshow/MATCH/matchfixers3 - thumbnail.webp', alt: 'Gras van de Buren - Matchfixers - foto door SopaMedia'
      }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoMATCH:Drie onsportieve sportfiguren lopen doelgericht rond. Communicerend met hun fluiten wordt de ideale speellocatie gekozen. Met serieuze blikken leggen ze secuur hun net neer en rollen deze langzaam uit. Ineens ontstaat er een speelveld.
      </br></br>
      En net op tijd ook, want de ballen vliegen al in het rond als confetti over het publiek! De helft van de toeschouwers zit plotseling in jouw team, en de rest? Tja, die zijn automatisch de tegenstander. De niet deelnemende deelnemer krijgt op zijn beurt een rode kaart. Welkom bij de meest chaotische pot ooit!
              
      `
    },
    {
      id: idCounter++,
      title: $localize`:@@titleHAAK:Oude Breisters`,
      info: $localize`:@@infoHAAK:In de hektiek van een festival is er behoefte aan ontprikkeling. Truus en Gerdie, breisters met een visie, hebben een duidelijk advies: Één bolletje wol en één haaknaald. 
      <br/><br/>
      Onder hun gehaakte parasol werken ze zwoegend aan de gekste patronen. Bierhouderkettingen, bikini\'s, armbandjes, condooms, mutsen of tampons, niets is te gek. Geen zeven kleuren stront in je onderbroek maar al die kleuren gebruiken om een nieuwe onderbroek te haken.`,
      image: "./assets/HAOB.jpg",
      imageText: $localize`:@@imageTextHAAK:Haak aan bij de Oude Breisters op Paaspop 2021`,
      href: 'deoudebreisters',
      hideLeesVerder: false,
      hideSlideshow: false,
      slideshowArray: [{
        image: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters I.webp', thumbImage: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters I - Thumbnail.webp', alt: $localize`:@@altTextHAAK1:Haak aan bij de oude Breisters`},{
        image: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters II.webp', thumbImage: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters II - Thumbnail.webp', alt: $localize`:@@altTextHAAK2:Haak aan bij de oude Breisters`},{
        image: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters - Rider.webp', thumbImage: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters - Rider - Thumbnail.webp', alt: $localize`:@@altTextHAAK3:Rider Haak aan bij de oude Breisters`},{
        image: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters III.webp', thumbImage: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters III - Thumbnail.webp', alt: $localize`:@@altTextHAAK4:Haak aan bij de oude Breisters`},{
        image: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters IV.webp', thumbImage: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters IV - Thumbnail.webp', alt: $localize`:@@altTextHAAK5:Haak aan bij de oude Breisters`},{
        image: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters V.webp', thumbImage: 'assets/slideshow/HAOB/Gras van de Buren - Haak aan bij de Oude Breisters V - Thumbnail.webp', alt: $localize`:@@altTextHAAK6:Haak aan bij de oude Breisters`
      }],
      showLongActInfo: false,
      longActInfo: $localize`:@@longActInfoHAAK:In de huiskamersfeer van de breisters wordt je hartelijk ontvangen. Voor je het door hebt zit je met je handen vol haakgerei. Op de oude beeldbuis speelt een klunzige instructievideo om het juiste fingerspitzengefühl te krijgen. Op de achtergrond hoor je een oude radio met fragmenten uit de tijd voordat haken hip werd. Truus en Gerdie staan klaar om je de fijne kneepjes van het vak bij te brengen. Ze geven begeleiding aan mensen die voor het eerst in de knoop zitten. Maar er is ook aandacht voor de deelnemer die graag zelf de touwtjes in handen houdt.
      <br/><br/>
      De oude breisters zorgen voor voldoende touw, haaknaalden en gezelligheid, zodat iedere bezoeker soepel aan kan haken. Ook nemen ze hun eigen bankjes met gehaakte kussens, poef, andere zitgelegenheid en kleedjes mee.      
      `
    },
    {
      id: idCounter++,
      title: $localize`:@@titleMAAT:Op Maat`,
      info: $localize`:@@infoMAAT:Is uw kunstgras helemaal dood? Wij bieden u onze kunstmest aan. Gras van de buren is specialist in het zaaien van creatief zaad over alles wat te maken heeft met serieus plezier. Naast eigen theaterproducties bieden we oplossingen in de ontwikkeling en uitvoering op maat. Lange vergaderingen voorzien van verse energie? Sociale cohesie gezocht in de buurt? Wij breken de boel graag open en roeren door de chaos die we zelf creëren.`,
      image: "./assets/mmm.webp",
      imageText: $localize`:@@imageTextMAAT:Op maat gemaakt door Gras van de Buren`,
      href: 'opmaat',
      hideSlideshow: true,
      hideLeesVerder: true,
      slideshowArray: [{}],
      showLongActInfo: false,
      longActInfo: ``
    }
    ];


  }
}
