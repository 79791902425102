<div class="row">
    <img src="./assets/logo - zwarte tekst ernaast.png" alt="Gras van de Buren logo - Interactief straattheater">
</div>
<!-- <div class="row ">
    <div class="col-12 no-padding">
        <a *ngFor="let act of acts" href="#{{act.href}}">{{act.title}}</a>
    </div>
</div> -->
<nav class="navbar navbar-static-top navbar-expand-lg">
    <div class="socials">
        <a class="navbar-brand" href="https://www.facebook.com/HetGrasVanDeBuren" target="_blank">
        <i class="fab fa-facebook-f"></i>
        </a> 
        <a class="navbar-brand second" href="https://www.instagram.com/gras.van.de.buren/" target="_blank">
            <i class="fab fa-instagram"></i>
        </a>
    </div> 

<!--     <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="languageDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="fi fi-nl"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
          <li><a class="dropdown-item" href="/en-GB/"><span class="fi fi-gb"></span> English</a></li>
        </ul>
    </div> -->
     
    <button class="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse flex-row-reverse" id="navbarNav">
        <div class="row ms-auto actsBlock">
                <ul *ngIf="acts" class="navbar-nav firstRow">         
                    <li class="nav-item">
                        <div #button class="navLinkButton" style="margin-right: 92px">
                            <a id="actId0" class="nav-link navLink" [routerLink]="[]" [fragment]="acts[0].href">{{acts[0].title}}</a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div #button class="navLinkButton" style="margin-right: 139px">
                            <a id="actId2" class="nav-link navLink" [routerLink]="''" [fragment]="acts[2].href">{{acts[2].title}}</a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div #button class="navLinkButton" style="margin-right: 92px">
                            <a id="actId4" class="nav-link navLink" [routerLink]="''" [fragment]="acts[4].href">{{acts[4].title}}</a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div #button class="navLinkButton">
                            <a id="actId6" class="nav-link navLink" [routerLink]="''" [fragment]="acts[6].href">{{acts[6].title}}</a>
                        </div>
                    </li>
                </ul>
                <ul *ngIf="acts" class="navbar-nav flex-row mb-2 firstRow">         
                    <li class="nav-item">
                        <div #button class="navLinkButton secondRow" style="margin-right:82px">
                            <a class="nav-link navLink" [routerLink]="''" [fragment]="acts[1].href">{{acts[1].title}}</a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div #button class="navLinkButton secondRow" style="margin-right:71px">
                            <a class="nav-link navLink" [routerLink]="''" [fragment]="acts[3].href">{{acts[3].title}}</a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div #button class="navLinkButton secondRow" style="margin-right:110px">
                            <a class="nav-link navLink" [routerLink]="''" [fragment]="acts[5].href">{{acts[5].title}}</a>
                        </div>
                    </li>
                </ul>
        </div>
    </div>
</nav> 