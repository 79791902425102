import { Component, OnInit, AfterViewInit, ElementRef, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.css']
})
export class NavigationbarComponent implements OnInit {

  public acts= [];

  constructor(private _projectService: ProjectService, private el: ElementRef, private renderer: Renderer2){}
  
  ngOnInit(){
    this.acts = this._projectService.getProjects();
  }

  @ViewChildren('button') childDivs: QueryList<ElementRef>;
  
  ngAfterViewInit() {
    const childWidths: number[] = [];

    this.childDivs.forEach((childDiv: ElementRef) => {
      const width = childDiv.nativeElement.offsetWidth;
      childWidths.push(width);
  });
  
  console.log('Widths of child divs:', childWidths);
  }

  public childWidths;
  
}

export class AppComponent {
  localesList = [
    { code: 'nl', label: 'Nederlands' },
    { code: 'en-GB', label: 'English '}
  ];
  
}

