import { Injectable } from '@angular/core';

@Injectable()
export class PartnersService {
/*     testFunction() {
        const root = document.documentElement;
        const marqueeElementsDisplayed:number = Number(getComputedStyle(root).getPropertyValue("--marquee-elements-displayed"));
        const marqueeContent = document.querySelector("ul.marquee-content");

        console.log(marqueeElementsDisplayed)
        root.style.setProperty("--marquee-elements", marqueeContent.children.length.toString());
    
        for(let i=0; i<2; i++) {
          marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
        }
    } */
}