import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'actsInfo',
  templateUrl: './actsInfo.component.html',
  styleUrls: ['./actsInfo.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ActsInfoComponent implements OnInit {

  public acts= [];

  getTopLeftBorder(actID: number){
    if(actID % 2 != 0){
        return '0px';
    }else{
        return '30px';
      }
    }

    getTopRightBorder(actID: number){
    if(actID % 2 != 0){
        return '30px';
    }else{
        return '0px';
      }
    }
  
  getBottomLeftBorder(isFoldoutOpen: boolean, actID: number){
    if(actID % 2 != 0){
      if(!isFoldoutOpen){
        return '30px';
      }
      return '0px';
    }
  }

  getBottomRightBorder(isFoldoutOpen: boolean, actID: number) {
    if (actID % 2 != 0) {
      return '0px';
    } else {
      if (!isFoldoutOpen) {
        return '30px';
      }
    }
  }

  getCssForActPicture(isPictureRight, isExtraInfoOpen){
    
  }

  constructor(private projectService: ProjectService, private activatedRoute: ActivatedRoute){}
  
  showActExtraInfo(act){
    if(!act.hideLeesVerder)
      act.showLongActInfo = !act.showLongActInfo;
  }

  removeAnchorFromUrl(url, index, length ){ 
    const param: string = url.slice(index+1,length)
    if( param.length > 0 )
      return param
  }

  ngOnInit(){
    this.acts = this.projectService.getProjects();

    //fix for dynamic act picture css

    //Open act long info from params in url
    this.activatedRoute.queryParams.subscribe(params => {
      if(Object.keys(params).length > 0) {
        let openActId = Number(params['openActId'])
        openActId--;
        this.acts[openActId].showLongActInfo = !this.acts[openActId].showLongActInfo
      }
    });
    
    //Animation of scrolling
    $(function() {
      $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    //Chrome workaround for anchor links
    $(document).ready(function () {
      var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      if (window.location.hash && isChrome) {
          setTimeout(function () {
              var hash = window.location.hash;
              window.location.hash = "";
              window.location.hash = hash;
          }, 300);
      }
    });
  }
}