import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { ActsInfoComponent } from './actsInfo/actsInfo.component';
import { NotfoundComponent } from './notfound/notfound.component';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'disabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 15],
    relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  { path: '', component: ActsInfoComponent },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
