import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-giglist',
  templateUrl: './giglist.component.html',
  styleUrls: ['./giglist.component.css']
})
export class GiglistComponent implements OnInit {

  public year: number = new Date().getFullYear();
  public dates: string[] = [];
  public eventNames: string[] = [];
  private readonly apiUrl = "https://api.trello.com/1/lists/6540b701b158a1c703482943/cards";
  private readonly apiKey = '4a17bd5dc46bf39e0bf2d2ee0dd1e86c';
  private readonly apiToken = 'ATTA60f873e1f4100f72df3f587040bb0e902120ab153b65f4f537c6a4b7ff0dc0e931AF9FF0';
  private readonly cacheKey = 'trelloCachedData';
  private readonly cacheTimeKey = 'trelloCacheTime';
  private readonly cacheDuration = 24 * 60 * 60 * 1000; // 24 uur in milliseconden

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    const cachedData = localStorage.getItem(this.cacheKey);
    const cacheTime = localStorage.getItem(this.cacheTimeKey);

    if (cachedData && cacheTime && (new Date().getTime() - Number(cacheTime) < this.cacheDuration)) {
      const { dates, eventNames } = JSON.parse(cachedData);
      this.dates = dates;
      this.eventNames = eventNames;
    } else {
      this.fetchCards();
    }
  }

  private fetchCards(): void {
    const params = {
      key: this.apiKey,
      token: this.apiToken
    };
    this.http.get<any[]>(this.apiUrl, { params }).subscribe(cards => {
      this.dates = [];
      this.eventNames = [];
      cards.forEach(card => this.formatListName(card.name));

      localStorage.setItem(this.cacheKey, JSON.stringify({ dates: this.dates, eventNames: this.eventNames }));
      localStorage.setItem(this.cacheTimeKey, new Date().getTime().toString());
    });
  }

  private formatListName(listName: string): void {
    if (!listName.includes('-')) {
      return;
    }
    const parts = listName.split('-').map(part => part.trim());
    if (parts.length === 3) {
      const month = parseInt(parts[0], 10);
      const monthName = new Date(0, month - 1).toLocaleString('default', { month: 'long' });
      if (!parts[1].includes('&')) {
        const day = parts[1].replace(/^0+/, '');
        this.dates.push(`${day} ${monthName}`);
      } else {
        const days = parts[1].replace(/\s/g, '').split('&');
        const startDay = days[0].replace(/^0+/, '');
        const endDay = days[days.length-1].replace(/^0+/, '');
        this.dates.push(`${startDay} t/m ${endDay} ${monthName}`);
      }
      this.eventNames.push(parts[2]);
    }
  }
  
  showMore = false; // To track if the "show more" button is clicked
  get showMoreButton(): boolean {
    return this.dates.length > 4;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }
}