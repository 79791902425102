import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title:string = 'Gras van de Buren - interactief straattheater';

  constructor(private titleService: Title) {
    this.titleService.setTitle($localize `${this.title}`);
  }
}