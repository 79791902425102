<div class="row">
    <div class="col-12 m-0 p-0 background">
        <img src="./assets/header.webp" class="rounded-lg"
            alt="Gras van de Buren zoekt en vangt een disco." i18n-alt title="Gras van de Buren - Foto door Tahné Kleijn"
            (click)="toggleShowGVDBInfo()">
    </div>
    <div class="col-12 shortText" (click)="toggleShowGVDBInfo()" [ngStyle]="{'border-bottom-left-radius' : hideGVDBInfo  ? '10px' : '0px',
                            'border-bottom-right-radius': hideGVDBInfo  ? '10px' : '0px',
                            'border-bottom': hideGVDBInfo  ? '1px solid black' : '0px'}">
        <h1 i18n class="slagzin">Interactief straattheater, kleurrijk als de tuin van je buren.</h1>
        <div class="col-12 center">
            <p style="text-align:center">
            <div class="col-12 leesVerder" [hidden]="!hideGVDBInfo">
                <p style="text-align:center">
                    <span i18n>meer info<br>
                        <div style="margin-top: -10px; margin-bottom: -9px;">
                            <br>
                        </div>
                    </span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-12 longText" [hidden]="hideGVDBInfo">
            <div i18n>
                Waar groeit gras van de buren? Wie maait gras van de buren? En waarom zaait Gras van de Buren verwarring?
                Allemaal vragen die vragen om een antwoord. Het antwoord is: ’t groenste gras groeit bij de buren. Wij zijn
                die buren.
            </div><br />
            <div i18n>
                Gras van de Buren is een straattheater gezelschap met een cartoonesk karakter. Met ludieke bouwwerken en
                rariteiten dagen we de toevallige passant uit om deel te nemen aan een interactief spektakel. Door de
                werkelijkheid in vreemde vormen te gieten, zetten we mensen op hun verkeerde been. Want we zien het als een
                serieuze zaak dat er te weinig gespeeld wordt.
            </div><br />
            <div i18n>
                De roots van Gras van de Buren liggen in de festivalwereld, maar onze wereld is groter dan alleen
                randprogrammering. Zo vermaken we een vermoeide vergaderzaal met een interactief intermezzo. Fleuren we de
                buurt op met merkwaardige community art projecten. En maken we ook producties op maat voor bijvoorbeeld een
                halloweenfeest bij een begrafenisonderneming, de opening van het nieuwe hondenpoepveldje of precies dat
                event waar jij nu (niet) aan denkt.
            </div><br />
            <div i18n>
                Onder TL-verlichting of de witte wolken, Gras van de buren groeit overal.
            </div><br />
            <div i18n>
                Meer informatie? <a href="#footer">Weet ons te vinden.</a>
            </div>
    </div>

